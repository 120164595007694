import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import Select from "react-tailwindcss-select";

import "reactflow/dist/style.css";
import "../styles/flowmap.css";
import "../styles/contextMenu.css";
import "../styles/group.css";

import { DateUtils, useAPI } from "../utils";

import * as Utils from "./components/misc/utils";
import WorkspaceTabs from "./components/workspaceHeader/workspaceTabs";
import InfiltrationCore from "./components/infiltration/core";
import Spinner from "./components/misc/spinner";

export default function Infiltration() {
  const [analyses, setAnalyses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [businessServices, setBusinessServices] = useState([]);
  const [currentAnalysisId, setCurrentAnalysisId] = useState(null);
  const [selectAnalysisOption, setSelectAnalysisOption] = useState({});
  const [currentAnalysis, setCurrentAnalysis] = useState(null);
  const [scoreHistory, setScoreHistory] = useState({ date: [], score: [] });

  const { listBusinessServices, listAnalyses } = useAPI();

  const [queryParameters] = useSearchParams();

  const { workspaceId } = useParams();

  const findAnalysisById = (id) => {
    var res = analyses.find((analysis) => analysis.id == id);

    if (res) {
      return res;
    } else {
      throw new Error(`Missing analysis with id ${id}`);
    }
  };

  const optionForAnalysis = (analysis) => {
    return {
      value: analysis.id,
      label: DateUtils.formatDate(analysis.updated_at),
    };
  };

  const analysisSelectOptions = () => {
    return analyses
      .sort((s1, s2) => DateUtils.compareDates(s1.updated_at, s2.updated_at))
      .map(optionForAnalysis);
  };

  useEffect(() => {
    listBusinessServices()
      .then(setBusinessServices)
      .catch((e) => void e);
    const fields = ["id", "updated_at", "result"];

    listAnalyses({
      status: "READY",
      type: "lateralization",
      workspace_id: workspaceId,
      _fields: fields,
    })
      .then(setAnalyses)
      .then(() => setLoading(false))
      .catch((e) => void e);
  }, []);

  useEffect(() => {
    if (analyses.length > 0) {
      if (queryParameters.get("id")) {
        setCurrentAnalysisId(findAnalysisById(queryParameters.get("id")).id);
      } else {
        setCurrentAnalysisId(
          analyses.sort((s1, s2) =>
            DateUtils.compareDates(s1.updated_at, s2.updated_at)
          )[0].id
        );
      }
    }
  }, [analyses]);

  useEffect(() => {
    if (currentAnalysisId) {
      setCurrentAnalysis(findAnalysisById(currentAnalysisId));
    }
  }, [currentAnalysisId]);

  useEffect(() => {
    if (currentAnalysis) {
      setSelectAnalysisOption(optionForAnalysis(currentAnalysis));
    } else {
      setSelectAnalysisOption({});
    }
  }, [currentAnalysis]);

  useEffect(() => {
    if (currentAnalysis) {
      // Build historical data

      const historyData = analyses.reduce(
        (acc, analysis) => {
          const date = analysis.updated_at;
          const result = JSON.parse(analysis.result);
          const score = Utils.computeNbSecuredPaths(result);

          return {
            date: [DateUtils.formatDate(date), ...acc.date],
            score: [score, ...acc.score],
          };
        },
        { date: [], score: [] }
      );
      setScoreHistory(historyData);
    } else {
      setScoreHistory({ date: [], score: [] });
    }
  }, [currentAnalysis]);

  return (
    <div className="flex flex-col h-screen bg-gray-background w-full p-12 justify-center ">
      <div className=""></div>
      <WorkspaceTabs workspaceId={workspaceId} />
      <div className="flex flex-col w-full h-full bg-white">
        {loading ? (
          <div className="flex flex-col w-full h-[calc(100vh-145px)] items-center justify-center">
            <Spinner size={20} />
          </div>
        ) : currentAnalysis ? (
          <>
            <div className="ml-4 mt-4 flex flex-row w-full items-center justify-start">
              <Select
                primaryColor={"indigo"}
                isSearchable={true}
                name="analysisSelect"
                onChange={(option) => {
                  setSelectAnalysisOption(option);
                  setCurrentAnalysisId(option.value);
                }}
                options={analysisSelectOptions()}
                value={selectAnalysisOption}
                classNames={{
                  menuButton: ({ isDisabled }) =>
                    `flex w-72 text-sm border border-gray-300 rounded shadow-sm text-gray-base transition-all duration-300 focus:outline-none 
                    ${
                      isDisabled
                        ? "bg-gray-200"
                        : "bg-white hover:border-gray-400 focus:border-blue-500 focus:ring focus:ring-blue-500/20"
                    }`,
                  menu: "absolute z-10 w-72 bg-white shadow-lg border rounded py-1 text-sm text-gray-200",
                  listItem: ({ isSelected }) =>
                    `block transition duration-200 px-2 py-2 cursor-pointer 
                      select-none truncate rounded ${
                        isSelected
                          ? `text-optistream-txt-white bg-blue-menu`
                          : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                      }`,
                }}
              />
            </div>
            <InfiltrationCore
              currentAnalysisId={currentAnalysisId}
              currentAnalysis={currentAnalysis}
              businessServices={businessServices}
              scoreHistory={scoreHistory}
            />
          </>
        ) : (
          <div className="w-full h-full flex justify-center items-center flex-col">
            <div className="text-lg text-optistream-txt-blue mb-8 font-bold">
              Run a 1st analysis from the sidebar on the Map page and analyze
              the results{" "}
            </div>
            <img className="w-96" src="/images/no_analysis.png" />
          </div>
        )}
      </div>
    </div>
  );
}
