import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";

import "reactflow/dist/style.css";
import "../styles/flowmap.css";
import "../styles/contextMenu.css";
import "../styles/group.css";
import {
  BookmarkPlus,
  SignpostSplit,
  SkipBackward,
} from "react-bootstrap-icons";

import { useAPI } from "../utils";

import DetailServiceAttackPath from "./components/high_risk_routes/detailServiceAttackPath";
import DetailServiceMap from "./components/high_risk_routes/detailServiceMap";
import WorkspaceTabs from "./components/workspaceHeader/workspaceTabs";
import * as ROUTES from "../constants/routes";

export default function HighRiskRoutesDetails() {
  const [section, setSection] = useState("Map");
  const [analysis, setAnalysis] = useState(null);
  const [businessService, setBusinessService] = useState(null);
  const [targetBusinessServicePaths, setTargetBusinessServicePaths] = useState(
    []
  );

  const { getAnalysis } = useAPI();

  const [queryParameters] = useSearchParams();

  const { analysisId } = useParams();
  const currentBusinessProcessId = queryParameters.get("procid");
  const fromWorkspace = queryParameters.get("workspace_id");

  const navigate = useNavigate();

  useEffect(() => {
    if (queryParameters.get("procid")) {
      const fields = ["id", "result", "snapshot.map", "snapshot.workspace_id"];

      getAnalysis({
        analysisId: analysisId,
        params: { _fields: fields },
      })
        .then((analysis) => {
          if (analysis.result) {
            analysis.result = JSON.parse(analysis.result);
          }
          return analysis;
        })
        .then(setAnalysis)
        .catch((e) => void e);
    } else {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (analysis && analysis.result && currentBusinessProcessId) {
      const selectedBusinessService = analysis.result.business_services.find(
        (bs) => bs.id === parseInt(currentBusinessProcessId)
      );

      setBusinessService(selectedBusinessService);

      const selectedBusinessServiceNodes =
        selectedBusinessService.associated_nodes;

      const targetPaths = analysis.result["result"].filter(
        (path) => selectedBusinessServiceNodes.includes(path.dst_node) //&& path.route
      );

      setTargetBusinessServicePaths(targetPaths);
    } else {
      setBusinessService(null);
    }
  }, [analysis, currentBusinessProcessId]);

  return (
    <div className="flex flex-col h-screen bg-gray-background w-full p-12 justify-center ">
      <div className=""></div>
      <WorkspaceTabs workspaceId={analysis?.snapshot.workspace_id} />
      <div className="flex flex-col w-full h-[calc(100vh-144px)] bg-white p-4">
        <div className="flex text-optistream-txt-blue font-bold text-base uppercase mb-4">
          Details on business service:{" "}
          <span className="text-optistream-txt-orange ml-1">
            {" "}
            {businessService && businessService.name}
          </span>
        </div>
        <div className="flex items-center text-base w-full justify-evenly">
          <button
            className={`flex flex-row items-center justify-center mr-2 bg-optistream-btn-default active:bg-optistream-btn-clicked text-optistream-txt-white h-10 text-sm w-48 font-bold rounded ${
              section === "Map" && "opacity-50"
            }`}
            onClick={(event) => setSection("Map")}
          >
            <SignpostSplit className="mr-2 text-base" /> Vulnerability map
          </button>

          <button
            className={`flex flex-row items-center justify-center mr-2 bg-optistream-btn-default active:bg-optistream-btn-clicked text-optistream-txt-white h-10 text-sm w-48 font-bold rounded ${
              section === "Paths" && "opacity-60"
            }`}
            onClick={(event) => setSection("Paths")}
          >
            <BookmarkPlus className="mr-2 text-base" /> Weakspots / path
          </button>

          <button
            className={`flex flex-row items-center justify-center mr-2 bg-optistream-btn-alternative 
                active:bg-btn-altClicked text-optistream-txt-white h-10 text-sm w-48 font-bold rounded`}
            onClick={(event) =>
              navigate(
                `${ROUTES.RISKY_ROUTES}/${analysis.snapshot.workspace_id}`
              )
            }
          >
            <SkipBackward className="mr-2 text-base" /> Go back
          </button>
        </div>
        <div className="flex w-full h-full overflow-auto">
          {" "}
          {section === "Map" &&
          analysis &&
          businessService &&
          targetBusinessServicePaths ? (
            <>
              <DetailServiceMap
                analysis={analysis}
                businessService={businessService}
                targetBusinessServicePaths={targetBusinessServicePaths}
              />
            </>
          ) : section === "Paths" && analysis ? (
            <DetailServiceAttackPath
              analysis={analysis}
              businessService={businessService}
              targetBusinessServicePaths={targetBusinessServicePaths}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}
