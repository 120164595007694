import axios from "axios";
import { env } from "../env";
import { useAlert } from "react-alert";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { saveEtag, sendEtag } from "./API/interceptors/etag";
import { ConcurrentEditingError } from "./API/error";

export function useAPI() {
  const axiosClient = axios.create({
    baseURL: env.REACT_APP_API_URL,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  const alert = useAlert();
  const { instance, accounts } = useMsal();
  const scopes = [`${env.REACT_APP_CLIENT_ID}/.default`];

  axiosClient.interceptors.request.use(async (config) => {
    const request = {
      scopes: scopes,
      account: accounts[0],
    };

    return instance.acquireTokenSilent(request).then((tokenResponse) => {
      config.headers.Authorization = `Bearer ${tokenResponse.accessToken}`;
      return Promise.resolve(config);
    });
  });

  axiosClient.interceptors.request.use(sendEtag);
  axiosClient.interceptors.response.use(saveEtag);

  const listWorkspaces = () => {
    return axiosClient
      .get("/workspaces/")
      .then((response) => response.data)
      .catch(handleError);
  };

  const getLatestSnapshot = (workspaceId) => {
    return axiosClient
      .get(`/workspaces/${workspaceId}/snapshots/last`)
      .then((response) => response.data)
      .catch(handleError);
  };

  const getWorkspaceById = (workspaceId) => {
    return axiosClient
      .get(`workspaces/${workspaceId}/`)
      .then((response) => response.data)
      .catch(handleError);
  };

  const deleteWorkspace = (workspaceId) => {
    return axiosClient
      .delete(`workspaces/${workspaceId}`)
      .then((response) => response.data)
      .catch(handleError);
  };

  const updateWorkspace = (workspaceId, params) => {
    return axiosClient
      .patch(`/workspaces/${workspaceId}/`, params)
      .then((response) => response.data)
      .catch(handleError);
  };

  const cloneWorkspace = (id) => {
    return axiosClient
      .post(`/workspaces/${id}/clone/`)
      .then((response) => response.data)
      .catch(handleError);
  };

  const getSnapshotById = (workspaceId, snapshotId) => {
    return axiosClient
      .get(`/workspaces/${workspaceId}/snapshots/${snapshotId}/`)
      .then((response) => response.data)
      .catch(handleError);
  };

  const updateSnapshot = (workspaceId, snapshotId, params) => {
    return axiosClient
      .post(`/workspaces/${workspaceId}/snapshots/${snapshotId}/`, params)
      .then((response) => response.data)
      .catch(handleError);
  };

  const createWorkspace = (name) => {
    return axiosClient
      .post("/workspaces/", {
        Name: name,
      })
      .then((response) => response.data)
      .catch(handleError);
  };

  const importWorkspaceFile = (file) => {
    const formData = new FormData();
    formData.append(`file`, file, file.name);
    return axiosClient
      .post("/workspaces/import", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => response.data)
      .catch(handleError);
  };

  const exportWorkspaceFile = (workspaceId, workspaceName) => {
    return axiosClient
      .get(`/workspaces/${workspaceId}/export`, {
        responseType: "blob",
      })
      .then((response) => {
        const href = URL.createObjectURL(response.data);

        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", `${workspaceName}.optistream`);
        document.body.appendChild(link);
        link.click();
        console.log(response.headers);

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch(handleError);
  };

  const checkAnalyses = (workspaceId, analysisTypes) => {
    return axiosClient
      .post(`/workspaces/${workspaceId}/analyses/check`, {
        analysis_types: analysisTypes,
      })
      .then((response) => response.data)
      .catch((e) => handleError(e, { UIHideErrors: true }));
  };

  const runAnalyses = (workspaceId, analysisTypes) => {
    return axiosClient
      .post(`/workspaces/${workspaceId}/analyses/run`, {
        analysis_types: analysisTypes,
      })
      .then((response) => response.data)
      .catch(handleError);
  };

  const uploadConfigFile = (workspaceId, snapshotId, elementId, file) => {
    const formData = new FormData();
    formData.append(`file`, file, file.name);
    return axiosClient
      .post(
        `/workspaces/${workspaceId}/snapshots/${snapshotId}/nodes/${elementId}/config/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          validateStatus: function (status) {
            return status < 400;
          },
        }
      )
      .then((response) => response.data)
      .catch(handleError);
  };

  const uploadFile = (workspaceId, snapshotId, file, type) => {
    const formData = new FormData();
    formData.append(`file`, file, file.name);
    return axiosClient
      .post(
        `/workspaces/${workspaceId}/snapshots/${snapshotId}/upload/${type}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => response.data)
      .catch((err) => handleError(err, { UIHideErrors: true }));
  };

  const getConfigFile = (workspaceId, snapshotId, elementId) => {
    return axiosClient
      .get(
        `/workspaces/${workspaceId}/snapshots/${snapshotId}/nodes/${elementId}/config/`
      )
      .then((response) => response.data)
      .catch((err) => handleError(err, { UIHideErrors: true }));
  };

  const listApplications = (bsId, params) => {
    return axiosClient
      .get(`/business_services/${bsId}/applications/`, { params: params })
      .then((response) => response.data)
      .catch(handleError);
  };

  const createApplication = (bsId, params) => {
    return axiosClient
      .post(`/business_services/${bsId}/applications/`, params)
      .then((response) => response.data)
      .catch(handleError);
  };

  const updateApplication = (bsId, appId, params) => {
    return axiosClient
      .put(`/business_services/${bsId}/applications/${appId}`, params)
      .then((response) => response.data)
      .catch(handleError);
  };

  const deleteApplication = (bsId, appId) => {
    return axiosClient
      .delete(`/business_services/${bsId}/applications/${appId}/`)
      .then((response) => response.data)
      .catch(handleError);
  };

  const listBusinessServices = () => {
    return axiosClient
      .get("/business_services/")
      .then((response) => response.data)
      .catch(handleError);
  };

  const createBusinessService = (params) => {
    return axiosClient
      .post("/business_services/", params)
      .then((response) => response.data)
      .catch(handleError);
  };

  const updateBusinessService = (id, params) => {
    return axiosClient
      .put(`business_services/${id}/`, params)
      .then((response) => response.data)
      .catch(handleError);
  };

  const deleteBusinessService = (businessServiceId) => {
    return axiosClient
      .delete(`business_services/${businessServiceId}`)
      .then((response) => response.data)
      .catch(handleError);
  };

  const traceroute = (
    workspaceId,
    snapshotId,
    sourceId,
    destinationId,
    withACL,
    application
  ) => {
    return axiosClient
      .post(`/workspaces/${workspaceId}/snapshots/${snapshotId}/traceroute`, {
        SourceId: sourceId,
        DestinationId: destinationId,
        WithACL: withACL,
        Application: application,
      })
      .then((response) => response.data)
      .catch(handleError);
  };

  const getDefaultGateway = (
    workspaceId,
    snapshotId,
    terminalNodeIP,
    networkNodeId
  ) => {
    return axiosClient
      .post(
        `/workspaces/${workspaceId}/snapshots/${snapshotId}/netmask_for_terminal_node`,
        {
          NetworkNodeId: networkNodeId,
          TerminalNodeIP: terminalNodeIP,
        }
      )
      .then((response) => response.data)
      .catch(handleError);
  };

  const listNodeACL = (workspaceId, snapshotId, nodeId) => {
    return axiosClient
      .get(`/workspaces/${workspaceId}/snapshots/${snapshotId}/acls/${nodeId}`)
      .then((response) => response.data)
      .catch(handleError);
  };

  const listAnalyses = (params) => {
    return axiosClient
      .get("/analyses/", { params: params })
      .then((response) => response.data)
      .catch(handleError);
  };

  const getAnalysis = ({ analysisId, params }) => {
    return axiosClient
      .get(`/analyses/${analysisId}/`, { params: params })
      .then((response) => response.data)
      .catch(handleError);
  };
  const syncAWSConfig = (
    workspaceId,
    snapshotId,
    accessKeyId,
    secretAccessKey,
    regions
  ) => {
    const data = {
      accessKeyId,
      secretAccessKey,
      regions,
    };

    return axiosClient
      .post(
        `/workspaces/${workspaceId}/snapshots/${snapshotId}/sync/aws/`,
        data
      )
      .then((response) => response.data)
      .catch((err) => handleError(err, { UIHideErrors: true }));
  };

  const handleError = (err, opts = {}) => {
    if (err instanceof InteractionRequiredAuthError) {
      return instance.acquireTokenRedirect({
        scopes: scopes,
        account: accounts[0],
      });
    } else if (err.response && err.response.status === 401) {
      return instance.acquireTokenRedirect({
        scopes: scopes,
        account: accounts[0],
      });
    } else if (err.response && err.response.status === 403) {
      alert.error("Unauthorized");
    } else if (err.response && err.response.status === 412) {
      alert.error("Modified by another user, changes discarded. Please reload");
      throw ConcurrentEditingError();
    } else if (err.response) {
      if (!opts.UIHideErrors) {
        alert.error(
          `Backend failed with error: ${err.response.statusText} (${err.response.status})`
        );
      }
    } else if (err.request) {
      console.log({ optistreamError: err });
      alert.error("Could not reach the backend");
    } else {
      console.log({ optistreamError: err });
      alert.error("Unknown error occured (details in logs)");
    }
    throw err;
  };

  return {
    listWorkspaces,
    getWorkspaceById,
    getLatestSnapshot,
    getSnapshotById,
    deleteWorkspace,
    cloneWorkspace,
    updateSnapshot,
    updateWorkspace,
    createWorkspace,
    importWorkspaceFile,
    exportWorkspaceFile,
    checkAnalyses,
    runAnalyses,
    uploadConfigFile,
    uploadFile,
    getConfigFile,
    createApplication,
    deleteApplication,
    updateApplication,
    listApplications,
    listBusinessServices,
    createBusinessService,
    updateBusinessService,
    deleteBusinessService,
    traceroute,
    getDefaultGateway,
    listNodeACL,
    listAnalyses,
    getAnalysis,
    syncAWSConfig,
  };
}
